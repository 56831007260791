import React from "react"

const Homebuyers = () => {
    const [isReady, setIsReady] = React.useState(false)
    const checkIfWindow = () => {
      if (typeof window !== "undefined") {
        return typeof window !== "undefined"
      }
      return typeof window !== "undefined"
    }
  
    React.useEffect(() => {
      if (checkIfWindow) {
        window.location.href = "/consumers"
        setIsReady(true)
      }
    }, [])
    return isReady && <></>
}

export default Homebuyers
